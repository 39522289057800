.image {
  display: block;
  width: 48px;
  height: 48px;

  border-radius: 8px;
  background: #e9e9e9;
}

.text {
  display: block;
  height: 20px;
  width: 200px;

  border-radius: 999px;
  background: #e9e9e9;
}
