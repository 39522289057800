.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.image {
  width: 100%;
  padding-bottom: 100%;
  background: #e9e9e9;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}

.lines {
  width: 64px;
  height: 18px;
  background: #e9e9e9;
  margin-bottom: 8px;
  border-radius: 8px;
}

.anotherLines {
  height: 16px;
  align-self: stretch;
  background: #e9e9e9;
  margin-bottom: 8px;
  border-radius: 8px;
}
