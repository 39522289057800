.center {
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
}

.center > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
