.header {
  position: fixed;
  z-index: 40;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  column-gap: 12px;
  align-items: center;

  margin: 0 auto;
  min-height: 58px;
  max-width: 430px;
  width: 100%;
  padding: 8px 16px;
  background-color: #ffffff;
}
