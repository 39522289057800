.gap {
  position: relative;
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  min-height: 58px;
  width: 100%;
  user-select: none;
  pointer-events: none;
}
